import * as React from 'react';
import RcTree, { TreeNode } from 'rc-tree';
import classNames from 'classnames';
import DirectoryTree from './DirectoryTree';
import Icon from '../icon';
import { ConfigConsumer } from '../config-provider';
import collapseMotion from '../_util/motion';
export default class Tree extends React.Component {
    constructor() {
        super(...arguments);
        this.renderSwitcherIcon = (prefixCls, switcherIcon, { isLeaf, expanded, loading, icon }) => {
            const { showLine } = this.props;
            if (loading) {
                return <Icon type="loading" className={`${prefixCls}-switcher-loading-icon`}/>;
            }
            if (isLeaf) {
                if (showLine) {
                    return icon || <Icon type="file" className={`${prefixCls}-switcher-line-icon`}/>;
                }
                return null;
            }
            const switcherCls = `${prefixCls}-switcher-icon`;
            if (switcherIcon) {
                return React.cloneElement(switcherIcon, {
                    className: classNames(switcherIcon.props.className || '', switcherCls),
                });
            }
            if (showLine) {
                return icon || (<Icon type={expanded ? 'minus-square' : 'plus-square'} className={`${prefixCls}-switcher-line-icon`} theme="outlined"/>);
            }
            return <Icon type="caret-down" className={switcherCls} theme="filled"/>;
        };
        this.setTreeRef = (node) => {
            this.tree = node;
        };
        this.renderTree = ({ getPrefixCls }) => {
            const { props } = this;
            const { prefixCls: customizePrefixCls, className, showIcon, showLine, switcherIcon, blockNode, children, } = props;
            const { checkable } = props;
            const prefixCls = getPrefixCls('tree', customizePrefixCls);
            return (<RcTree ref={this.setTreeRef} {...props} 
            // Hide icon in node when showLine is true, show icon in line always
            // https://github.com/ant-design/ant-design/issues/20090
            showIcon={showLine ? false : showIcon} prefixCls={prefixCls} className={classNames(className, {
                [`${prefixCls}-icon-hide`]: !showIcon,
                [`${prefixCls}-block-node`]: blockNode,
            })} checkable={checkable ? <span className={`${prefixCls}-checkbox-inner`}/> : checkable} switcherIcon={(nodeProps) => this.renderSwitcherIcon(prefixCls, switcherIcon, nodeProps)}>
        {children}
      </RcTree>);
        };
    }
    render() {
        return <ConfigConsumer>{this.renderTree}</ConfigConsumer>;
    }
}
Tree.TreeNode = TreeNode;
Tree.DirectoryTree = DirectoryTree;
Tree.defaultProps = {
    checkable: false,
    showIcon: false,
    motion: Object.assign(Object.assign({}, collapseMotion), { motionAppear: false }),
    blockNode: false,
};
